import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import API from '../../api/index'
import {
  Table,
  Row,
  Col,
  Button,
  Tag,
  Icon
} from 'antd'
import Action from './Action'

class RequirementList extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      requirements: [],
      result: [],
      redirect: undefined,
      page: 1
    }
    this.handleCreateRequirement = this.handleCreateRequirement.bind(this)
    this.handleViewRequirement = this.handleViewRequirement.bind(this)
    this.handleUpdateRequirement = this.handleUpdateRequirement.bind(this)
    this.handlePrevRequirement = this.handlePrevRequirement.bind(this)
    this.handleNextRequirement = this.handleNextRequirement.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = this.initialState
    this.columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Área',
        dataIndex: 'area.name',
        key: 'area.name'
      },
      {
        title: 'Prioridad',
        dataIndex: 'priority.name',
        key: 'priority.name'
      },
      {
        title: 'Activo',
        dataIndex: 'isActive',
        key: 'isActive',
        render: (text, record) => {
          return (
            <span>
              {record.isActive ? (
                <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
              ) : (
                <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
              )}
            </span>
          )
        }
      },
      {
        title: 'Acción',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => {
          return (
            <span>
              <Tag color='blue' onClick={() => this.handleViewRequirement(record._id)}>Ver detalle</Tag>
              <Tag color='orange' onClick={() => this.handleUpdateRequirement(record._id)}>Editar</Tag>
              <Action requirement={record} />
            </span>
          )
        }
      }
    ]
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  async getRequirements (page) {
    const requirements = await API.requirement.filter(page)
    if (requirements.errors) {
      this.updateState('redirect', '/home')
    } else {
      this.updateState('requirements', requirements)
    }
  }

  handleCreateRequirement () {
    this.updateState('redirect', '/type-requirement/create')
  }

  handleViewRequirement (id) {
    this.updateState('redirect', `/type-requirement/detail/${id}`)
  }

  handleUpdateRequirement (id) {
    this.updateState('redirect', `/type-requirement/update/${id}`)
  }

  handleChange (event) {
    this.updateState(event.target.name, event.target.value)
  }

  componentDidMount () {
    this.getRequirements(this.state.page)
  }

  handlePrevRequirement () {
    if (this.state.page > 1) {
      const page = this.state.page - 1
      this.updateState('page', page)
      this.getRequirements(page)
    }
  }

  handleNextRequirement () {
    if (this.state.requirements.length >= 10) {
      const page = this.state.page + 1
      this.updateState('page', page)
      this.getRequirements(page)
    }
  }

  render () {
    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />)
    }
    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Col span={8}>
            <h3><strong>Listado de tipos de requerimientos</strong></h3>
          </Col>
          <Col offset={8} span={8} style={{ textAlign: 'right' }}>
            <Button onClick={this.handleCreateRequirement}>Crear tipo de requerimiento</Button>
          </Col>
        </Row>
        <Table
          columns={this.columns}
          dataSource={this.state.requirements}
          size='small'
          pagination={{
            position: 'none'
          }}
          rowKey={record => record._id}
        />
        <div style={{
          textAlign: 'right',
          marginTop: '10px'
        }}>
          <ul className='ant-pagination '>
            <li className={this.state.page === 1 ? ('ant-pagination-disabled ant-pagination-prev') : ('ant-pagination-prev')}>
              <span onClick={this.handlePrevRequirement}>Anterior</span>
            </li>
            <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>{this.state.page}</li>
            <li className={this.state.requirements.length >= 10 ? ('ant-pagination-next') : ('ant-pagination-disabled ant-pagination-next')}>
              <span onClick={this.handleNextRequirement}>Siguiente</span>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default RequirementList
