import auth from './auth'
import area from './area'
import user from './user'
import priority from './priority'
import requirement from './requirement'
import maintenance from './maintenance'
import history from './history'
import nonconforming from './nonconforming'
import changeModification from './changeModification'

export default {
  area,
  auth,
  user,
  priority,
  requirement,
  maintenance,
  history,
  nonconforming,
  changeModification
}
