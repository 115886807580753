import React, { Component } from 'react'
import { Layout } from 'antd'
import { Container, Row, Col, Card, CardHeader } from 'reactstrap'
import { getItem } from '../api/utils'
import Header from './Header'
import Fondo from '../styles/img/fondo.png'

class Home extends Component {
  constructor (props) {
    super(props)
    this.state = {
      fullName: getItem('fullName'),
      profile: getItem('profile')
    }
  }

  render () {
    return (
      <Layout className='layout'>
        <Header item='home' />
        <div className='main-content' ref='mainContent'>
          <Container fluid>
            <Row className='mt-5 mb-5 '>
              <Col className='mb-5 mb-xl-0' xl='12'>
                <Card className='shadow'>
                  <CardHeader className='border-0'>
                    <Row className='align-items-center'>
                      <div className='col'>
                        <h3 className='mb-0'>Bienvenido <strong>{this.state.fullName}</strong>, {this.state.profile}</h3>
                      </div>
                    </Row>
                  </CardHeader>
                  <div className='text-center mb-5'>
                    <img src={Fondo} alt='Oftamolasersa' style={{width: '90%', maxHeight: 'calc(100vh - 64px - 100px)'}} />
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default Home
