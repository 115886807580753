import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Layout,
  Modal,
  Form,
  Input,
  Button,
  Select
} from 'antd'
import { Container, Row, Col, Card, CardHeader } from 'reactstrap'
import Header from '../Header'
import API from '../../api/index'

const Option = Select.Option

class PriorityUpdate extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      id: props.match.params.id,
      name: undefined,
      errorName: undefined,
      time: undefined,
      errorTime: undefined,
      redirect: undefined,
      temporality: 'Minutos'
    }
    this.state = this.initialState
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onBack = this.onBack.bind(this)
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  onChange (event) {
    this.updateState(event.target.name, event.target.value)
  }

  async onSubmit () {
    this.clearErrors()
    let time = this.state.time
    if (this.state.temporality === 'Hora(s)') {
      time = this.state.time * 60
    }
    if (this.state.temporality === 'Día(s)') {
      time = this.state.time * 60 * 24
    }
    const response = await API.priority.update(this.state.id, {
      name: this.state.name,
      time: time,
      timeString: `${this.state.time} ${this.state.temporality}`
    })
    if (response.updated) {
      Modal.success({
        title: 'La actualización de la prioridad',
        content: `La prioridad ${this.state.name} fue actualizada exitosamente`
      })
      this.clearFields()
      this.onBack()
    }
    if (response.errors) {
      this.makeErrors(response.errors)
    }
  }

  makeErrors (error) {
    if (error.name) {
      this.updateState('errorName', error.name.message)
    }
    if (error.time) {
      this.updateState('errorTime', error.time.message)
    }
  }

  clearFields () {
    this.updateState('name', undefined)
    this.updateState('time', undefined)
    this.clearErrors()
  }

  clearErrors () {
    this.updateState('errorName', undefined)
    this.updateState('errorTime', undefined)
  }

  async onBack () {
    this.updateState('redirect', '/priority')
  }

  async getPriority () {
    const priority = await API.priority.detail(this.state.id)
    if (priority._id === this.state.id) {
      let time
      let temporality = 'Minutos'
      if (priority.timeString) {
        const timeString = priority.timeString.split(' ')
        time = timeString[0]
        temporality = timeString[1]
      }
      this.setState({
        name: priority.name,
        time: time,
        temporality: temporality
      })
    } else {
      this.onBack()
    }
  }

  componentDidMount () {
    this.getPriority()
  }

  render () {
    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />)
    }
    return (
      <Layout className='layout'>
        <Header item='prority' />
        <div className='main-content' ref='mainContent'>
          <Container fluid>
            <Row className='mt-5 mb-5'>
              <Col className='mb-5 mb-xl-0' xl='12'>
                <Card className='shadow content-auto'>
                  <CardHeader className='border-0'>
                    <Row className='align-items-center'>
                      <div className='col'>
                        <h3 className='mb-0'>Actualizar prioridad</h3>
                        <h6 className='text-uppercase text-warning ls-1 mb-1'>
                          * Son campos obligatorios
                        </h6>
                      </div>
                      <div className='col text-right'>
                        <Button onClick={this.onBack}>Regresar</Button>
                        {' '}
                        <Button onClick={this.onSubmit}>Guardar</Button>
                      </div>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item
                          label='Nombre*'
                          validateStatus={this.state.errorName && ('error')}
                          help={this.state.errorName}
                        >
                          <Input
                            type='text'
                            name='name'
                            placeholder='Ingrese el nombre de la prioridad'
                            onChange={this.onChange}
                            value={this.state.name}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label='Tiempo*'
                          validateStatus={this.state.errorTime && ('error')}
                          help={this.state.errorTime}
                        >
                          <Input
                            type='number'
                            name='time'
                            placeholder='Ingrese el tiempo de correción'
                            addonAfter={(
                              <Select value={this.state.temporality} onChange={(value) => this.setState({ temporality: value })}>
                                <Option value='Minutos'>Minutos</Option>
                                <Option value='Hora(s)'>Hora(s)</Option>
                                <Option value='Día(s)'>Día(s)</Option>
                              </Select>
                            )}
                            onChange={this.onChange}
                            value={this.state.time}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default PriorityUpdate
