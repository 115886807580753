import React, { Component } from 'react'
import Menu from './menu/Menu'

class HeaderComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: props.item
    }
  }

  render () {
    return (
      <>
        <Menu item={this.state.item} />
      </>
    )
  }
}

export default HeaderComponent
