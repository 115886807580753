import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Layout,
  Form,
  Input,
  Button
} from 'antd'
import { Container, Row, Col, Card, CardHeader } from 'reactstrap'
import Header from '../Header'
import API from '../../api/index'

class PriorityDetail extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      id: props.match.params.id,
      name: undefined,
      area: undefined,
      priority: undefined,
      redirect: undefined
    }
    this.state = this.initialState
    this.onChange = this.onChange.bind(this)
    this.onBack = this.onBack.bind(this)
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  onChange (event) {
    this.updateState(event.target.name, event.target.value)
  }

  async onBack () {
    this.updateState('redirect', '/type-requirement')
  }

  async getRequirement () {
    const requirement = await API.requirement.detail(this.state.id)
    if (requirement._id) {
      this.setState({
        name: requirement.name,
        area: requirement.area.name,
        priority: requirement.priority.name
      })
    } else {
      this.onBack()
    }
  }

  componentDidMount () {
    this.getRequirement()
  }

  render () {
    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />)
    }
    return (
      <Layout className='layout'>
        <Header item='requirement' />
        <div className='main-content' ref='mainContent'>
          <Container fluid>
            <Row className='mt-5 mb-5'>
              <Col className='mb-5 mb-xl-0' xl='12'>
                <Card className='shadow content-auto'>
                  <CardHeader className='border-0'>
                    <Row className='align-items-center'>
                      <div className='col'>
                        <h3 className='mb-0'>Detalle de un tipo de requerimiento</h3>
                      </div>
                      <div className='col text-right'>
                        <Button onClick={this.onBack}>Regresar</Button>
                      </div>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item
                          label='Nombre'
                        >
                          <Input
                            type='text'
                            disabled
                            value={this.state.name}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Área' >
                          <Input
                            type='text'
                            disabled
                            value={this.state.area}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Prioridad' >
                          <Input
                            type='text'
                            disabled
                            value={this.state.priority}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default PriorityDetail
