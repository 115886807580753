import React, { Component } from 'react'
import { Alert, Form, Button, Input, Icon } from 'antd'
import { Redirect } from 'react-router-dom'
import API from '../api/index'
import Logo from '../styles/img/oftalmolaser.png'
import '../styles/css/Login.css'

const FormItem = Form.Item

class Login extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      email: '',
      password: '',
      error: false,
      messageError: '',
      redirect: false
    }
    this.state = this.initialState
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  onChange (event) {
    this.updateState(event.target.name, event.target.value)
  }

  async onSubmit () {
    if (this.state.email && this.state.password) {
      const response = await API.auth.login(this.state.email, this.state.password)
      if (response.token) {
        this.updateState('redirect', true)
      } else {
        this.updateState('error', true)
        this.updateState('messageError', 'Usuario o contraseña incorrectos intentalo nuevamente')
      }
    } else {
      this.updateState('error', true)
      this.updateState('messageError', 'Usuario y contraseña son obligatorios')
    }
  }

  render () {
    if (this.state.redirect) {
      return (<Redirect to='/home' />)
    }
    return (
      <div className='center-block-login container-login'>
        <div style={{ minWidth: '415px' }}>
          <div className='text-center'>
            <a href='/' className='login-title'>
              <img src={Logo} alt='Oftalmolasersa' width='450' />
            </a>
            <p className='gray'>Inicia sesión para continuar</p>
          </div>
          <div>
            <Form onSubmit={this.onSubmit}>
              {this.state.error && (
                <FormItem>
                  <Alert message={this.state.messageError} type='warning' showIcon />
                </FormItem>
              )}
              <FormItem>
                <Input prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Ingrese su email' onChange={this.onChange} value={this.state.email} type='email' name='email' id='email' />
              </FormItem>
              <FormItem>
                <Input prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />} type='password' name='password' id='password' placeholder='Ingrese su contraseña' onChange={this.onChange} value={this.state.password} />
              </FormItem>
              <FormItem>
                <a href='/reset/password'>¿Olvidaste tu contraseña?</a>
              </FormItem>
              <FormItem>
                <Button onClick={this.onSubmit}>Iniciar sesión</Button>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
