import React, { Component } from 'react'
import {
  Row,
  Col,
  Table
} from 'antd'
import API from '../../api/index'

class History extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      id: props.id,
      histories: []
    }
    this.state = this.initialState
    this.columns = [
      {
        title: 'Nombre',
        dataIndex: 'user.firstName',
        key: 'user.firstName',
        render: (text, record) => {
          return (
            <span>{record.user.firstName} {record.user.lastName}</span>
          )
        }
      },
      {
        title: 'Respuesta',
        dataIndex: 'observation',
        key: 'observation'
      },
      {
        title: 'Documentos',
        dataIndex: 'documents',
        key: 'documents',
        render: (text, record) => {
          let documents = []
          text.map((value, index) => {
            documents.push(<ul key={index}><li><a href={`https://api.oftalmolasersa.com.co/${value[0]}`}>{value[0]}</a></li></ul>)
          })
          return (
            <span>{documents}</span>
          )
        }
      },
      {
        title: 'Fecha de respuesta',
        dataIndex: 'created',
        key: 'created',
        render: (text, record) => {
          return (
            <span>{this.formatDate(record.created)}</span>
          )
        }
      }
    ]
  }

  getMonth (index) {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return months[index]
  }

  formatDate (date) {
    const newDate = new Date(date)
    return ('0' + (newDate.getDate())).slice(-2) + ' de ' + this.getMonth(newDate.getMonth()) + ' del ' + newDate.getFullYear() + ', ' + ('0' + newDate.getHours()).slice(-2) + ':' + ('0' + newDate.getMinutes()).slice(-2)
  }

  async getHistories () {
    const histories = await API.history.filterByMaintenance(this.state.id)
    this.setState({
      histories: histories
    })
  }

  componentDidMount () {
    this.getHistories()
  }

  render () {
    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Col span={8}>
            <h3><strong>Listado de respuestas</strong></h3>
          </Col>
        </Row>
        <Table
          columns={this.columns}
          dataSource={this.state.histories}
          size='small'
          rowKey={record => record._id}
        />
      </div>
    )
  }
}

export default History
