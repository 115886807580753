import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Form,
  Input,
  Layout,
  Button,
  Checkbox
} from 'antd'
import { Container, Row, Col, Card, CardHeader } from 'reactstrap'
import Header from '../Header'
import API from '../../api/index'
class UserDetil extends Component {
  constructor (props) {
    super(props)
    this.state = {
      id: props.match.params.id,
      user: {},
      start_date: undefined,
      update_date: undefined,
      date_join: undefined,
      redirect: undefined
    }
    this.onBack = this.onBack.bind(this)
  }

  getMonth (index) {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return months[index]
  }

  formatDate (date) {
    const newDate = new Date(date)
    return ('0' + (newDate.getDate())).slice(-2) + ' de ' + this.getMonth(newDate.getMonth()) + ' del ' + newDate.getFullYear()
  }

  async getUser () {
    const user = await API.user.detail(this.state.id)
    this.updateState('start_date', this.formatDate(user.start_date))
    this.updateState('update_date', this.formatDate(user.update_date))
    this.updateState('date_join', user.date_join)
    this.updateState('user', user)
  }

  onBack () {
    this.updateState('redirect', '/user')
  }

  componentWillMount () {
    this.getUser()
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  render () {
    let areas = []
    if (this.state.user.area) {
      for (let index = 0; index < this.state.user.area.length; index++) {
        const area = this.state.user.area[index]
        areas.push(area.name)
      }
    }
    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />)
    }
    return (
      <Layout className='layout'>
        <Header item='user' />
        <div className='main-content' ref='mainContent'>
          <Container fluid>
            <Row className='mt-5 mb-5'>
              <Col className='mb-5 mb-xl-0' xl='12'>
                <Card className='shadow content-auto'>
                  <CardHeader className='border-0'>
                    <Row className='align-items-center'>
                      <div className='col'>
                        <h3 className='mb-0'>Detalle del usuario</h3>
                      </div>
                      <div className='col text-right'>
                        <Button onClick={this.onBack}>Regresar</Button>
                      </div>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item label='Nombre'>
                          <Input
                            disabled
                            value={this.state.user.firstName}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Apellidos'>
                          <Input
                            disabled
                            value={this.state.user.lastName}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Tipo de usuario'>
                          <Input
                            disabled
                            value={this.state.user.profile}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item label='Correo electrónico'>
                          <Input
                            disabled
                            value={this.state.user.email}
                          />
                        </Form.Item>
                      </Col>
                      {this.state.user.area && (
                        <Col>
                          <Form.Item label='Área'>
                            <Input
                              disabled
                              value={areas}
                            />
                          </Form.Item>
                        </Col>
                      )}
                      <Col>
                        <Form.Item label='Permisos*'>
                          <Checkbox checked={this.state.user.isNotCompliant} disabled>No conforme</Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default UserDetil
