import React, { Component } from 'react'
import ChangeModificationList from './List'
import { Layout } from 'antd'
import { Container, Row, Col } from 'reactstrap'
import Header from '../Header'

class ChangeModification extends Component {
  render () {
    return (
      <Layout className='layout'>
        <Header item='changeModification' />
        <div className='main-content' ref='mainContent'>
          <Container fluid>
            <Row className='mt-5 mb-5 content-auto'>
              <Col className='mb-5 mb-xl-0' xl='12'>
                <ChangeModificationList />
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default ChangeModification
