import axios from 'axios'
import config from './config'
import { setItem } from './utils'

const URL = `${config.URL}/user/auth`

export async function login (email, password) {
  const result = await axios.post(`${URL}/token`, { email, password })
  if (result.data.token) {
    setItem('token', result.data.token)
    setItem('fullName', result.data.fullName)
    setItem('profile', result.data.profile)
    setItem('_id', result.data._id)
    setItem('isNotCompliant', result.data.isNotCompliant)
  }
  return result.data
}

export async function verify () {
  const result = await axios.get(`${URL}/verify`, config.configWithToken())
  return result.data
}

export async function resetPassword (email) {
  const result = await axios.post(`${URL}/reset/password`, {email}, config.configWithToken())
  return result.data
}

export async function getResetPassword (token) {
  const result = await axios.get(`${URL}/reset/password/${token}`, config.configWithToken())
  return result.data
}

export async function changePassword (token, password) {
  const result = await axios.post(`${URL}/reset/password/change`, {token, password}, config.configWithToken())
  return result.data
}

export default {
  login,
  verify,
  resetPassword,
  getResetPassword,
  changePassword
}
