import React, { Component } from 'react'
import { Tag } from 'antd'
import API from '../../api/index'

class Action extends Component {
  constructor (props) {
    super(props)
    this.state = {
      area: props.area,
      isActive: props.area.isActive
    }
    this.handleAction = this.handleAction.bind(this)
  }

  componentWillReceiveProps (newProps) {
    if (newProps.area !== this.state.area) {
      this.setState({
        area: newProps.area,
        isActive: newProps.area.isActive
      })
    }
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  async handleAction () {
    await API.area.update(this.state.area._id, {isActive: !this.state.isActive})
    this.updateState('isActive', !this.state.isActive)
  }

  render () {
    let color = 'green'
    let text = 'Activar'
    if (this.state.isActive) {
      color = 'red'
      text = 'Desactivar'
    }
    return (
      <Tag color={color} onClick={this.handleAction}>{text}</Tag>
    )
  }
}

export default Action
