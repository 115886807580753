import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Layout,
  Form,
  Input,
  Button,
  Checkbox,
  Select
} from 'antd'
import { Container, Col, Row, Card, CardHeader } from 'reactstrap'
import Header from '../Header'
import API from '../../api/index'

class NonconformingDetail extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      id: props.match.params.id,
      type: undefined,
      process: undefined,
      by: undefined,
      description: undefined,
      rejection: undefined,
      reprocessing: undefined,
      reclassification: undefined,
      concession: undefined,
      descriptionTreatment: undefined,
      documents: [],

      typeRequirement: undefined,
      status: undefined,
      observation: undefined,
      user: undefined,
      documents: [],
      errorDocuments: undefined,
      redirect: undefined,
      fullName: undefined,
      date: undefined
    }
    this.state = this.initialState
    this.onBack = this.onBack.bind(this)
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  async onBack () {
    this.updateState('redirect', '/nonconforming')
  }

  getMonth (index) {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return months[index]
  }

  formatDate (date) {
    const newDate = new Date(date)
    return ('0' + (newDate.getDate())).slice(-2) + ' de ' + this.getMonth(newDate.getMonth()) + ' del ' + newDate.getFullYear() + ', ' + ('0' + newDate.getHours()).slice(-2) + ':' + ('0' + newDate.getMinutes()).slice(-2)
  }

  async getNonconforming () {
    const record = await API.nonconforming.detail(this.state.id)
    if (record._id) {
      this.setState({
        documents: record.documents,
        type: record.type,
        by: record.by,
        process: record.process,
        description: record.description,
        rejection: record.rejection,
        reprocessing: record.reprocessing,
        reclassification: record.reclassification,
        concession: record.concession,
        descriptionTreatment: record.descriptionTreatment,
        treatmentVerification: record.treatmentVerification,
        treatmentNumber: record.treatmentNumber,
        treatmentDescription: record.treatmentDescription,
        fullName: `${record.user.firstName} ${record.user.lastName}`,
        date: this.formatDate(record.created)
      })
    } else {
      this.onBack()
    }
  }

  componentDidMount () {
    this.getNonconforming()
  }

  render () {
    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />)
    }
    let documents = []
    this.state.documents.map((value, index) => {
      documents.push(<ul key={index}><li><a href={`https://api.oftalmolasersa.com.co/${value[0]}`}>{value[0]}</a></li></ul>)
    })
    return (
      <Layout className='layout'>
        <Header item='nonconforming' />
        <div className='main-content' ref='mainContent'>
          <Container fluid>
            <Row className='mt-5 mb-5'>
              <Col className='mb-5 mb-xl-0' xl='12'>
                <Card className='shadow content-auto'>
                  <CardHeader className='border-0'>
                    <Row className='align-items-center'>
                      <div className='col'>
                        <h3 className='mb-0'>Detalle de la solicitud de producto o servicio no conforme</h3>
                      </div>
                      <div className='col text-right'>
                        <Button onClick={this.onBack}>Regresar</Button>
                      </div>
                    </Row>
                    <Row>
                      <Col>
                        <label>Código: C-FO-061</label>
                      </Col>
                      <Col>
                        <label>Versión: 1.2</label>
                      </Col>
                      <Col>
                        <label>Vigencia: Diciembre de 2012</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item label='Tipo'>
                          <Input
                            type='text'
                            disabled
                            value={this.state.type === 'service' ? 'Servicio' : 'Producto'}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Identificado por'>
                          <Input
                            type='text'
                            disabled
                            value={this.state.by}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item label='Usuario que reporta'>
                          <Input
                            type='text'
                            disabled
                            value={this.state.fullName}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Fecha de reporte'>
                          <Input
                            type='text'
                            disabled
                            value={this.state.date}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item label='Proceso'>
                          <Input.TextArea
                            rows={6}
                            disabled
                            value={this.state.process}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Descripción de la no conformidad'>
                          <Input.TextArea
                            rows={6}
                            disabled
                            value={this.state.description}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      {documents.length > 0 && (
                        <Col span={7} offset={1}>
                          <Form.Item
                            label='Documentos'
                          >
                            {documents}
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item label='Tratamiento de la no conformidad'>
                          <Checkbox checked={this.state.rejection} disabled>Rechazo / desecho</Checkbox>
                          <Checkbox checked={this.state.reprocessing} disabled>Reproceso / corrección</Checkbox>
                          <Checkbox checked={this.state.reclassification} disabled>Reclasificación / dar otro uso</Checkbox>
                          <Checkbox checked={this.state.concession} disabled>Concesión</Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item label='Descripción tratamiento' >
                          <Input.TextArea
                            rows={6}
                            name='descriptionTreatment'
                            disabled
                            placeholder='Descripción tratamiento'
                            value={this.state.descriptionTreatment}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item label='Requiere acción correctiva?*' >
                          <Select
                            style={{ width: '100%' }}
                            disabled
                            onChange={(value) => this.setState({'treatmentVerification': value})}
                            value={this.state.treatmentVerification}
                            placeholder='Seleccione una acción correctiva'
                          >
                            <Select.Option value='si'>Si</Select.Option>
                            <Select.Option value='no'>No</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Número*' >
                          <Input
                            name='treatmentNumber'
                            disabled
                            placeholder='Identificado por'
                            value={this.state.treatmentNumber}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item label='Verificación del tratamiento*' >
                          <Input.TextArea
                            rows={6}
                            name='treatmentDescription'
                            disabled
                            placeholder='Verificación del tratamiento'
                            value={this.state.treatmentDescription}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default NonconformingDetail
