import React, { Component } from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import Login from './components/Login'
import Authenticated from './Authenticated'
import Home from './components/Home'
import User from './components/user/User'
import UserDetail from './components/user/Detail'
import UserCreate from './components/user/Create'
import UserUpdate from './components/user/Update'
import Priority from './components/priority/Priority'
import PriorityCreate from './components/priority/Create'
import PriorityDetail from './components/priority/Detail'
import PriorityUpdate from './components/priority/Update'
import Area from './components/area/Area'
import AreaCreate from './components/area/Create'
import AreaDetail from './components/area/Detail'
import AreaUpdate from './components/area/Update'
import Requirement from './components/requirement/Requirement'
import RequirementCreate from './components/requirement/Create'
import RequirementDetail from './components/requirement/Detail'
import RequirementUpdate from './components/requirement/Update'
import Maintenance from './components/maintenance/Maintenance'
import MaintenanceCreate from './components/maintenance/Create'
import MaintenanceDetail from './components/maintenance/Detail'
import MaintenanceUpdate from './components/maintenance/Update'
import Report from './components/report/Report'
import ResetPassword from './components/password/ResetPassword'
import ChangePassword from './components/password/ChangePassword'
import Nonconforming from './components/nonconforming/Nonconforming'
import NonconformingCreate from './components/nonconforming/Create'
import NonconformingDetail from './components/nonconforming/Detail'
import NonconformingUpdate from './components/nonconforming/Update'
import NonconformingReport from './components/nonconforming/Report'
import ChangeModification from './components/changeModification/ChangeModification'
import ChangeModificationCreate from './components/changeModification/Create'
import ChangeModificationDetail from './components/changeModification/Detail'
import ChangeModificationUpdate from './components/changeModification/Update'
import ChangeModificationReport from './components/changeModification/Report'

import './App.css'

class App extends Component {
  render () {
    return (
      <Router>
        <div>
          <Switch>
            <Route exact path='/' component={Login} />
            <Route exact path='/reset/password' component={ResetPassword} />
            <Route exact path='/reset/password/:token' component={ChangePassword} />
            <Authenticated path='/home' component={Home} />
            <Authenticated exact path='/user' component={User} />
            <Authenticated exact path='/user/create' component={UserCreate} />
            <Authenticated exact path='/user/detail/:id' component={UserDetail} />
            <Authenticated exact path='/user/update/:id' component={UserUpdate} />
            <Authenticated exact path='/priority' component={Priority} />
            <Authenticated exact path='/priority/create' component={PriorityCreate} />
            <Authenticated exact path='/priority/detail/:id' component={PriorityDetail} />
            <Authenticated exact path='/priority/update/:id' component={PriorityUpdate} />
            <Authenticated exact path='/area' component={Area} />
            <Authenticated exact path='/area/create' component={AreaCreate} />
            <Authenticated exact path='/area/detail/:id' component={AreaDetail} />
            <Authenticated exact path='/area/update/:id' component={AreaUpdate} />
            <Authenticated exact path='/type-requirement' component={Requirement} />
            <Authenticated exact path='/type-requirement/create' component={RequirementCreate} />
            <Authenticated exact path='/type-requirement/detail/:id' component={RequirementDetail} />
            <Authenticated exact path='/type-requirement/update/:id' component={RequirementUpdate} />
            <Authenticated exact path='/maintenance' component={Maintenance} />
            <Authenticated exact path='/maintenance/create' component={MaintenanceCreate} />
            <Authenticated exact path='/maintenance/detail/:id' component={MaintenanceDetail} />
            <Authenticated exact path='/maintenance/update/:id' component={MaintenanceUpdate} />
            <Authenticated exact path='/nonconforming' component={Nonconforming} />
            <Authenticated exact path='/nonconforming/create' component={NonconformingCreate} />
            <Authenticated exact path='/nonconforming/detail/:id' component={NonconformingDetail} />
            <Authenticated exact path='/nonconforming/update/:id' component={NonconformingUpdate} />
            <Authenticated exact path='/nonconforming/report' component={NonconformingReport} />
            <Authenticated exact path='/change-modification' component={ChangeModification} />
            <Authenticated exact path='/change-modification/create' component={ChangeModificationCreate} />
            <Authenticated exact path='/change-modification/detail/:id' component={ChangeModificationDetail} />
            <Authenticated exact path='/change-modification/update/:id' component={ChangeModificationUpdate} />
            <Authenticated exact path='/change-modification/report' component={ChangeModificationReport} />
            <Authenticated exact path='/report' component={Report} />
          </Switch>
        </div>
      </Router>
    )
  }
}

export default App
