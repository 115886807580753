import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Layout,
  Form,
  Input,
  Button
} from 'antd'
import { Container, Row, Col, Card, CardHeader } from 'reactstrap'
import Header from '../Header'
import API from '../../api/index'
class PriorityDetail extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      id: props.match.params.id,
      name: undefined,
      time: undefined,
      redirect: undefined
    }
    this.state = this.initialState
    this.onBack = this.onBack.bind(this)
  }

  async onBack () {
    this.setState({
      redirect: '/priority'
    })
  }

  async getPriority () {
    const priority = await API.priority.detail(this.state.id)
    if (priority._id) {
      this.setState({
        name: priority.name,
        time: priority.timeString
      })
    } else {
      this.onBack()
    }
  }

  componentDidMount () {
    this.getPriority()
  }

  render () {
    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />)
    }
    return (
      <Layout className='layout'>
        <Header item='prority' />
        <div className='main-content' ref='mainContent'>
          <Container fluid>
            <Row className='mt-5 mb-5'>
              <Col className='mb-5 mb-xl-0' xl='12'>
                <Card className='shadow content-auto'>
                  <CardHeader className='border-0'>
                    <Row className='align-items-center'>
                      <div className='col'>
                        <h3 className='mb-0'>Detalle de prioridad</h3>
                      </div>
                      <div className='col text-right'>
                        <Button onClick={this.onBack}>Regresar</Button>
                      </div>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item label='Nombre'>
                          <Input
                            disabled
                            value={this.state.name}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Tiempo'>
                          <Input
                            disabled
                            value={this.state.time}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default PriorityDetail
