import React from 'react'
import { Link } from 'react-router-dom'
import {
  Layout,
  Form,
  Button,
  DatePicker,
  Select,
  Modal
} from 'antd'
import { Container, Col, Row, Card, CardHeader } from 'reactstrap'
import API from '../../api/index'
import Header from '../Header'
const { RangePicker } = DatePicker

const Report = (props) => {
  const [type, setType] = React.useState('')
  const [process, setProcess] = React.useState('')
  const [user, setUser] = React.useState([])
  const [users, setUsers] = React.useState([])
  const [typeReport, setTypeReport] = React.useState('')
  const [startDate, setStartDate] = React.useState('')
  const [endDate, setEndDate] = React.useState('')


  function createRowUser (values) {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.firstName} {value.lastName}</Select.Option>)
    })
    return row
  }

  async function getUsers () {
    const data = await API.user.all()
    setUsers(data)
  }

  React.useEffect(() => {
    getUsers()
  }, [])

  async function onSubmit () {
    if (startDate) {
      let data = {}
      if (typeReport === 'fulfilled') {
        if (type) {
          data['type'] = type
        }
        if (user.length > 0) {
          data['user'] = user
        }
        if (process) {
          data['process'] = process
        }
        if (startDate) {
          data['created'] = {
            '$gte': startDate,
            '$lte': endDate
          }
        }
      } else if (typeReport === 'FrequentRequests' || typeReport === 'AreaRequests') {
        data = {
          startDate: startDate,
          endDate: endDate
        }
      } else if (typeReport === 'UserRequests') {
        data = {
          startDate: startDate,
          endDate: endDate,
          users: user || []
        }
      }
      await API.nonconforming.report(data, typeReport)
      Modal.success({
        title: 'Generación de reporte',
        content: 'El reporte se está generando, se enviará al correo electrónico'
      })
    } else {
      Modal.error({
        title: 'Rango de fechas',
        content: 'Por favor seleccione un rango de fecha'
      })
    }
  }

  const rowUsers = createRowUser(users)

  return (
    <Layout className='layout'>
      <Header item='report-nonconforming' />
      <div className='main-content'>
          <Container fluid>
            <Row className='mt-5 mb-5'>
              <Col className='mb-5 mb-xl-0' xl='12'>
                <Card className='shadow content-auto'>
                  <CardHeader className='border-0'>
                    <Row className='align-items-center'>
                      <div className='col'>
                        <h3 className='mb-0'>Reporte</h3>
                      </div>
                      <div className='col text-right'>
                        <Link to='/nonconforming' className='ant-btn'>
                          Regresar
                        </Link>
                      </div>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item label='Rango de fecha'>
                          <RangePicker onChange={(date, dateString) => {
                            setStartDate(dateString[0])
                            setEndDate(dateString[1])
                          }} />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Tipo*' >
                          <Select
                            style={{ width: '100%' }}
                            onChange={(value) => setType(value)}
                            value={type}
                            placeholder='Seleccione un tipo'
                          >
                            <Select.Option value='service'>Servicio</Select.Option>
                            <Select.Option value='product'>Producto</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Proceso*' >
                          <Select
                            style={{ width: '100%' }}
                            onChange={(value) => setProcess(value)}
                            value={process}
                            placeholder='Seleccione un proceso'
                          >
                            <Select.Option value='Consulta externa'>Consulta externa</Select.Option>
                            <Select.Option value='Apoyo diagnóstico'>Apoyo diagnóstico</Select.Option>
                            <Select.Option value='Cirugía'>Cirugía</Select.Option>
                            <Select.Option value='Gestión de la información'>Gestión de la información</Select.Option>
                            <Select.Option value='Gestión de mantenimiento'>Gestión de mantenimiento</Select.Option>
                            <Select.Option value='Direccionamiento estratégico'>Direccionamiento estratégico</Select.Option>
                            <Select.Option value='Gestión de suministro'>Gestión de suministro</Select.Option>
                            <Select.Option value='Gestión de talento humano'>Gestión de talento humano</Select.Option>
                            <Select.Option value='Gestión de calidad y mejoramiento'>Gestión de calidad y mejoramiento</Select.Option>
                            <Select.Option value='Gestión contable y financiera'>Gestión contable y financiera</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item label='Identificado por*'>
                          <Select
                            mode='multiple'
                            style={{ width: '100%' }}
                            onChange={(value) => setUser(value)}
                            value={user}
                            placeholder='Seleccione un usuario'
                          >
                            {rowUsers}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Tipo de reporte'>
                          <Select
                            style={{ width: '100%' }}
                            onChange={(value) => setTypeReport(value)}
                            value={typeReport}
                            placeholder='Seleccione un tipo de reporte'
                          >
                            <Select.Option value='fulfilled'>Reporte completo</Select.Option>
                            <Select.Option value='FrequentRequests'>Solicitudes más frecuentes</Select.Option>
                            <Select.Option value='AreaRequests'>Solicitudes por área</Select.Option>
                            <Select.Option value='UserRequests'>Solicitudes realizadas por usuario</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Generar reporte'>
                          <Button onClick={() => onSubmit()}>Descargar reporte</Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
    </Layout>
  )
}

export default Report
