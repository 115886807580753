import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import API from '../../api/index'
import {
  Table,
  Row,
  Col,
  Button,
  Tag,
  Icon
} from 'antd'

class PriorityList extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      maintenance: [],
      result: [],
      redirect: undefined,
      page: 1
    }
    this.handleCreateMantenance = this.handleCreateMantenance.bind(this)
    this.handleViewMantenance = this.handleViewMantenance.bind(this)
    this.handleUpdateMantenance = this.handleUpdateMantenance.bind(this)
    this.handlePrevMantenance = this.handlePrevMantenance.bind(this)
    this.handleNextMantenance = this.handleNextMantenance.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = this.initialState
    this.columns = [
      {
        title: 'Tipo de requerimiento',
        dataIndex: 'typeRequirement.name',
        key: 'typeRequirement.name'
      },
      {
        title: 'Estado',
        dataIndex: 'status',
        key: 'status'
      },
      {
        title: 'Fecha de creación',
        dataIndex: 'created',
        key: 'created',
        render: (text, record) => {
          return (
            <span>{this.formatDate(record.created)}</span>
          )
        }
      },
      {
        title: 'Fecha de actualización',
        dataIndex: 'updated',
        key: 'updated',
        render: (text, record) => {
          let date = '------------------------------'
          if (record.updated) {
            date = this.formatDate(record.updated)
          }
          return (
            <span>{date}</span>
          )
        }
      },
      {
        title: 'Cumplió',
        dataIndex: 'fulfilled',
        key: 'fulfilled',
        render: (text, record) => {
          return (
            <span>
              {record.fulfilled ? (
                <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
              ) : (
                <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
              )}
            </span>
          )
        }
      },
      {
        title: 'Acción',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => {
          return (
            <span>
              <Tag color='blue' onClick={() => this.handleViewMantenance(record._id)}>Ver detalle</Tag>
              {record.status !== 'Solucionado' && (
                <Tag color='orange' onClick={() => this.handleUpdateMantenance(record._id)}>Editar</Tag>
              )}
            </span>
          )
        }
      }
    ]
  }

  getMonth (index) {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return months[index]
  }

  formatDate (date) {
    const newDate = new Date(date)
    return ('0' + (newDate.getDate())).slice(-2) + ' de ' + this.getMonth(newDate.getMonth()) + ' del ' + newDate.getFullYear() + ', ' + ('0' + newDate.getHours()).slice(-2) + ':' + ('0' + newDate.getMinutes()).slice(-2)
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  async getMantenances (page) {
    const maintenance = await API.maintenance.all(page)
    this.updateState('maintenance', maintenance)
  }

  handleCreateMantenance () {
    this.updateState('redirect', '/maintenance/create')
  }

  handleViewMantenance (id) {
    this.updateState('redirect', `/maintenance/detail/${id}`)
  }

  handleUpdateMantenance (id) {
    this.updateState('redirect', `/maintenance/update/${id}`)
  }

  handleChange (event) {
    this.updateState(event.target.name, event.target.value)
  }

  componentDidMount () {
    this.getMantenances(this.state.page)
  }

  handlePrevMantenance () {
    if (this.state.page > 1) {
      const page = this.state.page - 1
      this.updateState('page', page)
      this.getMantenances(page)
    }
  }

  handleNextMantenance () {
    if (this.state.maintenance.length >= 10) {
      const page = this.state.page + 1
      this.updateState('page', page)
      this.getMantenances(page)
    }
  }

  render () {
    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />)
    }
    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Col span={8}>
            <h3><strong>Listado de mantenimientos</strong></h3>
          </Col>
          <Col offset={8} span={8} style={{ textAlign: 'right' }}>
            <Button onClick={this.handleCreateMantenance}>Crear solicitud de mantenimiento</Button>
          </Col>
        </Row>
        <Table
          columns={this.columns}
          dataSource={this.state.maintenance}
          size='small'
          pagination={{
            position: 'none'
          }}
          rowKey={record => record._id}
        />
        <div style={{
          textAlign: 'right',
          marginTop: '10px'
        }}>
          <ul className='ant-pagination '>
            <li className={this.state.page === 1 ? ('ant-pagination-disabled ant-pagination-prev') : ('ant-pagination-prev')}>
              <span onClick={this.handlePrevMantenance}>Anterior</span>
            </li>
            <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>{this.state.page}</li>
            <li className={this.state.maintenance.length >= 10 ? ('ant-pagination-next') : ('ant-pagination-disabled ant-pagination-next')}>
              <span onClick={this.handleNextMantenance}>Siguiente</span>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default PriorityList
