import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Layout,
  Modal,
  Form,
  Input,
  Button,
  Select
} from 'antd'
import { Container, Col, Row, Card, CardHeader } from 'reactstrap'
import Header from '../Header'
import API from '../../api/index'

class PriorityCreate extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      name: undefined,
      errorName: undefined,
      area: undefined,
      errorArea: undefined,
      priority: undefined,
      errorPriority: undefined,
      redirect: undefined,
      areas: [],
      priorities: []
    }
    this.state = this.initialState
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.handleChangeArea = this.handleChangeArea.bind(this)
    this.handleChangePriority = this.handleChangePriority.bind(this)
    this.onBack = this.onBack.bind(this)
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  createRow (values) {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.name}</Select.Option>)
    })
    return row
  }

  onChange (event) {
    this.updateState(event.target.name, event.target.value)
  }

  async onSubmit () {
    this.clearErrors()
    const response = await API.requirement.create({
      name: this.state.name,
      area: this.state.area,
      priority: this.state.priority
    })
    if (response._id) {
      Modal.success({
        title: 'Creación de un tipo de requerimiento',
        content: `El tipo de requerimiento ${this.state.name} fue creado exitosamente`
      })
      this.clearFields()
      this.onBack()
    }
    if (response.errors) {
      this.makeErrors(response.errors)
    }
  }

  makeErrors (error) {
    if (error.name) {
      this.updateState('errorName', error.name.message)
    }
    if (error.area) {
      this.updateState('errorArea', error.area.message)
    }
    if (error.priority) {
      this.updateState('errorPriority', error.priority.message)
    }
  }

  clearFields () {
    this.updateState('name', undefined)
    this.updateState('area', undefined)
    this.updateState('priority', undefined)
    this.clearErrors()
  }

  clearErrors () {
    this.updateState('errorName', undefined)
    this.updateState('errorArea', undefined)
    this.updateState('errorPriority', undefined)
  }

  async handleChangeArea (value) {
    this.updateState('area', value)
  }

  async handleChangePriority (value) {
    this.updateState('priority', value)
  }

  async onBack () {
    this.updateState('redirect', '/type-requirement')
  }

  async getAreas () {
    const areas = await API.area.all()
    this.updateState('areas', areas)
  }

  async getPriorties () {
    const priorities = await API.priority.all()
    this.updateState('priorities', priorities)
  }

  componentDidMount () {
    this.getAreas()
    this.getPriorties()
  }

  render () {
    const areas = this.createRow(this.state.areas)
    const priorities = this.createRow(this.state.priorities)
    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />)
    }
    return (
      <Layout className='layout'>
        <Header item='requirement' />
        <div className='main-content' ref='mainContent'>
          <Container fluid>
            <Row className='mt-5 mb-5'>
              <Col className='mb-5 mb-xl-0' xl='12'>
                <Card className='shadow content-auto'>
                  <CardHeader className='border-0'>
                    <Row className='align-items-center'>
                      <div className='col'>
                        <h3 className='mb-0'>Nuevo tipo de requerimiento</h3>
                        <h6 className='text-uppercase text-warning ls-1 mb-1'>
                          * Son campos obligatorios
                        </h6>
                      </div>
                      <div className='col text-right'>
                        <Button onClick={this.onBack}>Regresar</Button>
                        {' '}
                        <Button onClick={this.onSubmit}>Guardar</Button>
                      </div>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item
                          label='Nombre*'
                          validateStatus={this.state.errorName && ('error')}
                          help={this.state.errorName}
                        >
                          <Input
                            type='text'
                            name='name'
                            placeholder='Ingrese el nombre del tipo de requerimiento'
                            onChange={this.onChange}
                            value={this.state.name}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label='Área*'
                          validateStatus={this.state.errorArea && ('error')}
                          help={this.state.errorArea}
                        >
                          <Select
                            style={{ width: '100%' }}
                            onChange={this.handleChangeArea}
                            value={this.state.area}
                            placeholder='Seleccione un área'
                          >
                            {areas}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label='Prioridad*'
                          validateStatus={this.state.errorPriority && ('error')}
                          help={this.state.errorPriority}
                        >
                          <Select
                            style={{ width: '100%' }}
                            onChange={this.handleChangePriority}
                            value={this.state.priority}
                            placeholder='Seleccione una prioridad'
                          >
                            {priorities}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default PriorityCreate
