import React, { Component } from 'react'
import { Form, Button, Input, Icon, Modal } from 'antd'
import { Redirect } from 'react-router-dom'
import API from '../../api/index'
import Logo from '../../styles/img/oftalmolaser.png'
import '../../styles/css/Login.css'

const FormItem = Form.Item

class ChangePassword extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      token: props.match.params.token,
      password: undefined,
      repeatPassword: undefined,
      redirect: false
    }
    this.state = this.initialState
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  onChange (event) {
    this.updateState(event.target.name, event.target.value)
  }

  async onSubmit () {
    if (this.state.password) {
      if (this.state.password === this.state.repeatPassword) {
        API.auth.changePassword(this.state.token, this.state.password)
        Modal.success({
          title: 'Olvidaste tu contraseña?',
          content: `La contraseña fue cambiada exitosamente.`
        })
        this.updateState('redirect', true)
      } else {
        Modal.error({
          title: 'Recuperación de contraseña',
          content: 'Las contraseñas no coinciden, por favor intente de nuevo.'
        })
      }
    } else {
      Modal.error({
        title: 'Recuperación de contraseña',
        content: 'Por favor ingrese una nueva contraseña'
      })
    }
  }

  async validateToken () {
    const response = await API.auth.getResetPassword(this.state.token)
    if (!response.ok) {
      this.updateState('redirect', true)
    }
  }

  componentDidMount () {
    this.validateToken()
  }

  render () {
    if (this.state.redirect) {
      return (<Redirect to='/home' />)
    }
    return (
      <div className='center-block-login container-login'>
        <div style={{ minWidth: '415px' }}>
          <div className='text-center'>
            <a href='/' className='login-title'>
              <img src={Logo} alt='Oftalmolasersa' />
            </a>
            <p className='gray'>Restablecer contraseña</p>
          </div>
          <div>
            <Form onSubmit={this.onSubmit}>
              <FormItem>
                <Input prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Ingrese su nueva contraseña' onChange={this.onChange} value={this.state.password} type='password' name='password' />
              </FormItem>
              <FormItem>
                <Input prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Ingrese de nuevo la contraseña' onChange={this.onChange} value={this.state.repeatPassword} type='password' name='repeatPassword' />
              </FormItem>
              <FormItem>
                <Button onClick={this.onSubmit}>Restablecer contraseña</Button>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}

export default ChangePassword
