import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import API from '../../api/index'
import { getItem } from '../../api/utils'
import {
  Table,
  Row,
  Col,
  Button,
  Tag,
  Icon
} from 'antd'
import Action from './Action'

class UserList extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      users: [],
      result: [],
      redirect: undefined,
      page: 1,
      type: getItem('type')
    }
    this.handleCreateUser = this.handleCreateUser.bind(this)
    this.handleViewUser = this.handleViewUser.bind(this)
    this.handleUpdateUser = this.handleUpdateUser.bind(this)
    this.handlePrevUser = this.handlePrevUser.bind(this)
    this.handleNextUser = this.handleNextUser.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = this.initialState
    this.columns = [
      {
        title: 'Nombre',
        dataIndex: 'firstName',
        key: 'firstName'
      },
      {
        title: 'Apellidos',
        dataIndex: 'lastName',
        key: 'lastName'
      },
      {
        title: 'Tipo de usuario',
        dataIndex: 'profile',
        key: 'profile'
      },
      {
        title: 'Correo electrónico',
        dataIndex: 'email',
        key: 'email'
      },
      {
        title: 'Activo',
        dataIndex: 'isActive',
        key: 'isActive',
        render: (text, record) => {
          return (
            <span>
              {record.isActive ? (
                <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
              ) : (
                <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
              )}
            </span>
          )
        }
      },
      {
        title: 'Acción',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => {
          return (
            <span>
              <Tag color='blue' onClick={() => this.handleViewUser(record._id)}>Ver detalle</Tag>
              <Tag color='orange' onClick={() => this.handleUpdateUser(record._id)}>Editar</Tag>
              <Action user={record} />
            </span>
          )
        }
      }
    ]
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  async getUsers (page) {
    const users = await API.user.filter(page)
    this.updateState('users', users)
  }

  handleCreateUser () {
    this.updateState('redirect', '/user/create')
  }

  handleViewUser (id) {
    this.updateState('redirect', `/user/detail/${id}`)
  }

  handleUpdateUser (id) {
    this.updateState('redirect', `/user/update/${id}`)
  }

  handleChange (event) {
    this.updateState(event.target.name, event.target.value)
  }

  componentDidMount () {
    this.getUsers(this.state.page)
  }

  handlePrevUser () {
    if (this.state.page > 1) {
      const page = this.state.page - 1
      this.updateState('page', page)
      this.getUsers(page)
    }
  }

  handleNextUser () {
    if (this.state.users.length >= 10) {
      const page = this.state.page + 1
      this.updateState('page', page)
      this.getUsers(page)
    }
  }

  render () {
    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />)
    }
    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Col span={8}>
            <h3><strong>Listado de usuarios</strong></h3>
          </Col>
          <Col offset={8} span={8} style={{ textAlign: 'right' }}>
            <Button onClick={this.handleCreateUser}>Crear usuario</Button>
          </Col>
        </Row>
        <Table
          columns={this.columns}
          dataSource={this.state.users}
          size='small'
          pagination={{
            position: 'none'
          }}
          rowKey={record => record._id}
        />
        <div style={{
          textAlign: 'right',
          marginTop: '10px'
        }}>
          <ul className='ant-pagination '>
            <li className={this.state.page === 1 ? ('ant-pagination-disabled ant-pagination-prev') : ('ant-pagination-prev')}>
              <span onClick={this.handlePrevUser}>Anterior</span>
            </li>
            <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>{this.state.page}</li>
            <li className={this.state.users.length >= 10 ? ('ant-pagination-next') : ('ant-pagination-disabled ant-pagination-next')}>
              <span onClick={this.handleNextUser}>Siguiente</span>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default UserList
