import axios from 'axios'
import config from './config'
const URL = `${config.URL}/maintenance/history`

export async function create (data) {
  const result = await axios.post(`${URL}/create`, data, config.configWithToken())
  return result.data
}

export async function filterByMaintenance (maintenance) {
  const result = await axios.get(`${URL}/filter/by-maintenance/${maintenance}`, config.configWithToken())
  return result.data
}

export default {
  create,
  filterByMaintenance
}
