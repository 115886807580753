import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Layout,
  Modal,
  Form,
  Input,
  Button,
  Select,
  Checkbox
} from 'antd'
import { Container, Row, Col, Card, CardHeader } from 'reactstrap'
import Header from '../Header'
import API from '../../api/index'

class UserUpdate extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      id: props.match.params.id,
      first_name: undefined,
      errorFirstName: undefined,
      last_name: undefined,
      errorLastName: undefined,
      type_user: undefined,
      errorTypeUser: undefined,
      email: undefined,
      errorEmail: undefined,
      password: undefined,
      errorPassword: undefined,
      repeatPassword: undefined,
      errorRepeatPassword: undefined,
      redirect: undefined,
      area: undefined,
      errorArea: undefined,
      areas: [],
      isNotCompliant: undefined
    }
    this.state = this.initialState
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.handleChangeType = this.handleChangeType.bind(this)
    this.handleChangeArea = this.handleChangeArea.bind(this)
    this.onBack = this.onBack.bind(this)
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  onChange (event) {
    this.updateState(event.target.name, event.target.value)
  }

  async onSubmit () {
    this.clearErrors()
    if (this.state.password === this.state.repeatPassword) {
      let response = {}
      if (this.state.area) {
        response = await API.user.update(this.state.id, {
          firstName: this.state.first_name,
          lastName: this.state.last_name,
          profile: this.state.type_user,
          email: this.state.email,
          password: this.state.password,
          area: this.state.area,
          isNotCompliant: this.state.isNotCompliant
        })
      } else {
        this.updateState('errorArea', 'El área encargada es obligatoria.')
      }
      if (response.updated) {
        Modal.success({
          title: 'Actualización de un usuario',
          content: `El usuario ${this.state.first_name} fue actualizado exitosamente`
        })
        this.onBack()
      }
      if (response.errors) {
        this.makeErrors(response.errors)
      }
    } else {
      this.updateState('errorRepeatPassword', 'Las contraseñas no coinciden, por favor intente de nuevo.')
    }
  }

  makeErrors (error) {
    if (error.first_name) {
      this.updateState('errorFirstName', error.first_name.message)
    }
    if (error.last_name) {
      this.updateState('errorLastName', error.last_name.message)
    }
    if (error.password) {
      this.updateState('errorPassword', error.password.message)
    }
    if (error.area) {
      this.updateState('errorArea', error.area.message)
    }
    if (error.type_user) {
      this.updateState('errorTypeUser', error.type_user.message)
    }
    if (error.email) {
      this.updateState('errorEmail', error.email.message)
    }
  }

  clearFields () {
    this.updateState('first_name', undefined)
    this.updateState('last_name', undefined)
    this.updateState('type_user', undefined)
    this.updateState('email', undefined)
    this.updateState('password', undefined)
    this.updateState('repeatPassword', undefined)
    this.updateState('area', undefined)
    this.clearErrors()
  }

  clearErrors () {
    this.updateState('errorFirstName', undefined)
    this.updateState('errorLastName', undefined)
    this.updateState('errorPassword', undefined)
    this.updateState('errorRepeatPassword', undefined)
    this.updateState('errorTypeUser', undefined)
    this.updateState('errorEmail', undefined)
    this.updateState('errorArea', undefined)
  }

  async handleChangeType (value) {
    this.updateState('type_user', value)
  }

  async handleChangeArea (value) {
    this.updateState('area', value)
  }

  async getAreas () {
    const areas = await API.area.all()
    this.updateState('areas', areas)
  }

  async onBack () {
    this.updateState('redirect', '/user')
  }

  async getUser () {
    const user = await API.user.detail(this.state.id)
    this.updateState('first_name', user.firstName)
    this.updateState('last_name', user.lastName)
    this.updateState('type_user', user.profile)
    this.updateState('email', user.email)
    this.updateState('isNotCompliant', user.isNotCompliant)
    let areas = []
    for (let index = 0; index < user.area.length; index++) {
      const area = user.area[index]
      areas.push(area._id)
    }
    if (user.area) {
      this.updateState('area', areas)
    }
  }

  componentWillMount () {
    this.getAreas()
    this.getUser()
  }

  createRow (values) {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.name}</Select.Option>)
    })
    return row
  }

  render () {
    const areas = this.createRow(this.state.areas)
    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />)
    }
    return (
      <Layout className='layout'>
        <Header item='user' />
        <div className='main-content' ref='mainContent'>
          <Container fluid>
            <Row className='mt-5 mb-5'>
              <Col className='mb-5 mb-xl-0' xl='12'>
                <Card className='shadow content-auto'>
                  <CardHeader className='border-0'>
                    <Row className='align-items-center'>
                      <div className='col'>
                        <h3 className='mb-0'>Actualizar usuario</h3>
                        <h6 className='text-uppercase text-warning ls-1 mb-1'>
                          * Son campos obligatorios
                        </h6>
                      </div>
                      <div className='col text-right'>
                        <Button onClick={this.onBack}>Regresar</Button>
                        {' '}
                        <Button onClick={this.onSubmit}>Guardar</Button>
                      </div>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item
                          label='Nombre*'
                          validateStatus={this.state.errorFirstName && ('error')}
                          help={this.state.errorFirstName}
                        >
                          <Input
                            type='text'
                            name='first_name'
                            placeholder='Ingrese el nombre del usuario'
                            onChange={this.onChange}
                            value={this.state.first_name}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label='Apellidos*'
                          validateStatus={this.state.errorLastName && ('error')}
                          help={this.state.errorLastName}
                        >
                          <Input
                            type='text'
                            name='last_name'
                            placeholder='Ingrese los apellidos del usuario'
                            onChange={this.onChange}
                            value={this.state.last_name}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label='Tipo de usuario*'
                          validateStatus={this.state.errorTypeUser && ('error')}
                          help={this.state.errorTypeUser}
                        >
                          <Select
                            style={{ width: '100%' }}
                            onChange={this.handleChangeType}
                            value={this.state.type_user}
                            placeholder='Seleccione un tipo de usuario'
                          >
                            <Select.Option value='Administrador'>Administrador</Select.Option>
                            <Select.Option value='Solucionador'>Solucionador</Select.Option>
                            <Select.Option value='Cliente'>Cliente</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item
                          label='Correo electrónico*'
                          validateStatus={this.state.errorEmail && ('error')}
                          help={this.state.errorEmail}
                        >
                          <Input
                            type='email'
                            name='email'
                            placeholder='Ingrese el correo del usuario'
                            onChange={this.onChange}
                            value={this.state.email}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label='Contraseña*'
                          validateStatus={this.state.errorPassword && ('error')}
                          help={this.state.errorPassword}
                        >
                          <Input
                            type='password'
                            name='password'
                            placeholder='Ingrese la contraseña del usuario'
                            onChange={this.onChange}
                            value={this.state.password}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label='Repetir Contraseña*'
                          validateStatus={this.state.errorRepeatPassword && ('error')}
                          help={this.state.errorRepeatPassword}
                        >
                          <Input
                            type='password'
                            name='repeatPassword'
                            placeholder='Repita la contraseña del usuario'
                            onChange={this.onChange}
                            value={this.state.repeatPassword}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item
                          label='Área encargada*'
                          validateStatus={this.state.errorArea && ('error')}
                          help={this.state.errorArea}
                        >
                          <Select
                            mode='multiple'
                            style={{ width: '100%' }}
                            onChange={this.handleChangeArea}
                            value={this.state.area}
                            placeholder='Seleccione una área'
                          >
                            {areas}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Permisos*'>
                          <Checkbox onChange={() => this.setState({'isNotCompliant': !this.state.isNotCompliant})} checked={this.state.isNotCompliant}>No conforme</Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default UserUpdate
