import React, { Component } from 'react'
import { Tag } from 'antd'
import API from '../../api/index'

class Action extends Component {
  constructor (props) {
    super(props)
    this.state = {
      requirement: props.requirement,
      isActive: props.requirement.isActive
    }
    this.handleAction = this.handleAction.bind(this)
  }

  componentWillReceiveProps (newProps) {
    if (newProps.requirement !== this.state.requirement) {
      this.setState({
        requirement: newProps.requirement,
        isActive: newProps.requirement.isActive
      })
    }
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  async handleAction () {
    await API.requirement.update(this.state.requirement._id, {isActive: !this.state.isActive})
    this.updateState('isActive', !this.state.isActive)
  }

  render () {
    let color = 'green'
    let text = 'Activar'
    if (this.state.isActive) {
      color = 'red'
      text = 'Desactivar'
    }
    return (
      <Tag color={color} onClick={this.handleAction}>{text}</Tag>
    )
  }
}

export default Action
