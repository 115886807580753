import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import {
  Layout,
  Modal,
  Form,
  Input,
  Button,
  Select,
  Upload,
  Icon
} from 'antd'
import { Container, Col, Row, Card, CardHeader } from 'reactstrap'
import config from '../../api/config'
import Header from '../Header'
import History from './History'
import API from '../../api/index'

class MaintenanceUpdate extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      id: props.match.params.id,
      typeRequirement: undefined,
      errorTypeRequirement: undefined,
      status: undefined,
      errorStatus: undefined,
      observation: undefined,
      errorObservation: undefined,
      user: undefined,
      errorUser: undefined,
      documents: [],
      errorDocuments: undefined,
      redirect: undefined,
      requirements: [],
      histories: [],
      response: undefined,
      fullName: undefined,
      date: undefined
    }
    this.state = this.initialState
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.handleChangeTypeRequirement = this.handleChangeTypeRequirement.bind(this)
    this.handleChangeStatus = this.handleChangeStatus.bind(this)
    this.onBack = this.onBack.bind(this)
    this.columns = [
      {
        title: 'Nombre',
        dataIndex: 'user.firstName',
        key: 'user.firstName',
        render: (text, record) => {
          return (
            <span>{record.user.firstName} {record.user.lastName}</span>
          )
        }
      },
      {
        title: 'Respuesta',
        dataIndex: 'observation',
        key: 'observation'
      },
      {
        title: 'Fecha de respuesta',
        dataIndex: 'created',
        key: 'created',
        render: (text, record) => {
          return (
            <span>{this.formatDate(record.created)}</span>
          )
        }
      }
    ]
  }

  getMonth (index) {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return months[index]
  }

  formatDate (date) {
    const newDate = new Date(date)
    return ('0' + (newDate.getDate())).slice(-2) + ' de ' + this.getMonth(newDate.getMonth()) + ' del ' + newDate.getFullYear() + ', ' + ('0' + newDate.getHours()).slice(-2) + ':' + ('0' + newDate.getMinutes()).slice(-2)
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  createRow (values) {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.name}</Select.Option>)
    })
    return row
  }

  onChange (event) {
    this.updateState(event.target.name, event.target.value)
  }

  async onSubmit () {
    this.clearErrors()
    const response = await API.maintenance.update(this.state.id, {
      typeRequirement: this.state.typeRequirement,
      status: this.state.status
    })
    if (this.state.response) {
      await API.history.create({
        maintenance: this.state.id,
        observation: this.state.response,
        documents: this.state.documents
      })
    }
    if (response.updated) {
      Modal.success({
        title: 'Actualización de una solicitud de mantenimiento',
        content: `La solicitud de mantenimiento fue actualizado exitosamente`
      })
      this.clearFields()
      this.onBack()
    }
    if (response.errors) {
      this.makeErrors(response.errors)
    }
  }

  makeErrors (error) {
    if (error.name) {
      this.updateState('errorName', error.name.message)
    }
    if (error.area) {
      this.updateState('errorArea', error.area.message)
    }
    if (error.priority) {
      this.updateState('errorPriority', error.priority.message)
    }
  }

  clearFields () {
    this.updateState('name', undefined)
    this.updateState('area', undefined)
    this.updateState('priority', undefined)
    this.clearErrors()
  }

  clearErrors () {
    this.updateState('errorName', undefined)
    this.updateState('errorArea', undefined)
    this.updateState('errorPriority', undefined)
  }

  async handleChangeStatus (value) {
    this.updateState('status', value)
  }

  async handleChangeTypeRequirement (value) {
    this.updateState('typeRequirement', value)
  }

  async onBack () {
    this.updateState('redirect', '/maintenance')
  }

  async getRequirements () {
    const requirements = await API.requirement.all()
    this.updateState('requirements', requirements)
  }

  async getMaintenance () {
    const maintenance = await API.maintenance.detail(this.state.id)
    if (maintenance._id) {
      const histories = await API.history.filterByMaintenance(this.state.id)
      this.setState({
        typeRequirement: maintenance.typeRequirement._id,
        status: maintenance.status,
        observation: maintenance.observation,
        documents: maintenance.documents,
        histories: histories,
        fullName: `${maintenance.user.firstName} ${maintenance.user.lastName}`,
        date: this.formatDate(maintenance.created)
      })
    } else {
      this.onBack()
    }
  }

  componentDidMount () {
    this.getMaintenance()
    this.getRequirements()
  }

  render () {
    let documents = []
    this.state.documents.map((value, index) => {
      documents.push(<ul key={index}><li><a href={`https://api.oftalmolasersa.com.co/${value[0]}`}>{value[0]}</a></li></ul>)
    })
    const requirements = this.createRow(this.state.requirements)
    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />)
    }
    return (
      <Layout className='layout'>
        <Header item='maintenance' />
        <div className='main-content' ref='mainContent'>
          <Container fluid>
            <Row className='mt-5 mb-5'>
              <Col className='mb-5 mb-xl-0' xl='12'>
                <Card className='shadow content-auto'>
                  <CardHeader className='border-0'>
                    <Row className='align-items-center'>
                      <div className='col'>
                        <h3 className='mb-0'>Actualizar la solicitud de requerimiento</h3>
                        <h6 className='text-uppercase text-warning ls-1 mb-1'>
                          * Son campos obligatorios
                        </h6>
                      </div>
                      <div className='col text-right'>
                        <Button onClick={this.onBack}>Regresar</Button>
                        {' '}
                        <Button onClick={this.onSubmit}>Guardar</Button>
                      </div>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item
                          label='Tipo de requerimiento*'
                          validateStatus={this.state.errorTypeRequirement && ('error')}
                          help={this.state.errorTypeRequirement}
                        >
                          <Select
                            style={{ width: '100%' }}
                            onChange={this.handleChangeTypeRequirement}
                            value={this.state.typeRequirement}
                            placeholder='Seleccione un tipo de requerimiento'
                          >
                            {requirements}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label='Estado de solicitud*'
                          validateStatus={this.state.errorStatus && ('error')}
                          help={this.state.errorStatus}
                        >
                          <Select
                            style={{ width: '100%' }}
                            onChange={this.handleChangeStatus}
                            value={this.state.status}
                            placeholder='Seleccione un estado de solicitud'
                          >
                            <Select.Option value='Pendiente' key='Pendiente'>Pendiente</Select.Option>
                            <Select.Option value='En proceso' key='En proceso'>En proceso</Select.Option>
                            <Select.Option value='Solucionado' key='Solucionado'>Solucionado</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item label='Usuario que reporta'>
                          <Input
                            type='text'
                            disabled
                            value={this.state.fullName}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Fecha de reporte'>
                          <Input
                            type='text'
                            disabled
                            value={this.state.date}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label='Observación*'
                          validateStatus={this.state.errorObservation && ('error')}
                          help={this.state.errorObservation}
                        >
                          <Input.TextArea
                            disabled
                            rows={6}
                            name='observation'
                            placeholder='Describa la solicitud'
                            onChange={this.onChange}
                            value={this.state.observation}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      {documents.length > 0 && (
                        <Col span={7} offset={1}>
                          <Form.Item
                            label='Documentos'
                          >
                            {documents}
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                    <History id={this.state.id} />
                    <Row>
                      <Col>
                        <Form.Item label='Respuesta'>
                          <Input.TextArea
                            rows={6}
                            name='response'
                            placeholder='Respuesta'
                            onChange={this.onChange}
                            value={this.state.response}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          label='Documentos'
                        >
                          <Upload
                            name='documents'
                            action={`${config.URL}/maintenance/maintenance/create/upload`}
                            headers={config.configWithToken().headers}
                            onChange={(info) => {
                              if (info.file.status === 'done') {
                                let documents = this.state.documents
                                documents.push(info.file.response.path)
                                this.setState({
                                  documents: documents
                                })
                              }
                            }}
                            onRemove={(info) => {
                              let documents = this.state.documents
                              documents.pop(info.response.path)
                              this.setState({
                                documents: documents
                              })
                            }}
                          >
                            <Button>
                              <Icon type='upload' /> Subir documento
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default MaintenanceUpdate
