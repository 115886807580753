import React, { Component } from 'react'
import {
  Layout,
  Select,
  DatePicker,
  Form,
  Button,
  Modal
} from 'antd'
import { Container, Row, Col, Card, CardHeader } from 'reactstrap'
import Header from '../Header'
import API from '../../api/index'
const { RangePicker } = DatePicker

class Report extends Component {
  constructor (props) {
    super(props)
    this.state = {
      area: undefined,
      areas: [],
      requirements: [],
      requirement: undefined,
      user: undefined,
      users: [],
      startDate: undefined,
      endDate: undefined,
      type_report: 'fulfilled'
    }
    this.handleChangeArea = this.handleChangeArea.bind(this)
    this.handleChangeRequirement = this.handleChangeRequirement.bind(this)
    this.handleChangeUser = this.handleChangeUser.bind(this)
    this.handleTypeReport = this.handleTypeReport.bind(this)
    this.handleChangeRangeDate = this.handleChangeRangeDate.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  async handleTypeReport (value) {
    this.updateState('type_report', value)
  }

  async handleChangeArea (value) {
    this.updateState('area', value)
    const requirements = await API.requirement.filterByArea(value)
    const ids = []
    for (let index = 0; index < requirements.length; index++) {
      const requirement = requirements[index]
      ids.push(requirement._id)
    }
    this.updateState('requirements', requirements)
    this.updateState('requirement', ids)
  }

  handleChangeRequirement (value) {
    this.updateState('requirement', value)
  }

  handleChangeUser (value) {
    this.updateState('user', value)
  }

  async getAreas () {
    const areas = await API.area.all()
    this.updateState('areas', areas)
  }

  async getUsers () {
    const users = await API.user.all()
    this.updateState('users', users)
  }

  async handleChangeRangeDate (date, dateString) {
    this.setState({
      startDate: dateString[0],
      endDate: dateString[1]
    })
  }

  async onSubmit () {
    if (this.state.startDate) {
      let data = {}
      if (this.state.type_report === 'fulfilled') {
        if (this.state.requirement) {
          data['typeRequirement'] = this.state.requirement
        }
        if (this.state.user) {
          data['user'] = this.state.user
        }
        if (this.state.startDate) {
          data['created'] = {
            '$gte': this.state.startDate,
            '$lte': this.state.endDate
          }
        }
      } else if (this.state.type_report === 'FrequentRequests' || this.state.type_report === 'AreaRequests') {
        data = {
          startDate: this.state.startDate,
          endDate: this.state.endDate
        }
      } else if (this.state.type_report === 'UserRequests') {
        data = {
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          users: this.state.user || []
        }
      }
      await API.maintenance.report(data, this.state.type_report)
      Modal.success({
        title: 'Generación de reporte',
        content: 'El reporte se está generando, se enviará al correo electrónico'
      })
    } else {
      Modal.error({
        title: 'Rango de fechas',
        content: 'Por favor seleccione un rango de fecha'
      })
    }
  }

  componentWillMount () {
    this.getAreas()
    this.getUsers()
  }

  createRow (values) {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.name}</Select.Option>)
    })
    return row
  }

  createRowUser (values) {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.firstName} {value.lastName}</Select.Option>)
    })
    return row
  }

  render () {
    const areas = this.createRow(this.state.areas)
    const requirements = this.createRow(this.state.requirements)
    const users = this.createRowUser(this.state.users)
    return (
      <Layout className='layout'>
        <Header item='report' />
        <div className='main-content' ref='mainContent'>
          <Container fluid>
            <Row className='mt-5 mb-5'>
              <Col className='mb-5 mb-xl-0' xl='12'>
                <Card className='shadow content-auto'>
                  <CardHeader className='border-0'>
                    <Row className='align-items-center'>
                      <div className='col'>
                        <h3 className='mb-0'>Reporte</h3>
                      </div>
                      <div className='col text-right'>
                        <Button onClick={this.onBack}>Regresar</Button>
                        {' '}
                        <Button onClick={this.onSubmit}>Guardar</Button>
                      </div>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item label='Rango de fecha'>
                          <RangePicker onChange={this.handleChangeRangeDate} />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Área'>
                          <Select
                            style={{ width: '100%' }}
                            onChange={this.handleChangeArea}
                            value={this.state.area}
                            placeholder='Seleccione una área'
                          >
                            {areas}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Requerimiento'>
                          <Select
                            mode='multiple'
                            style={{ width: '100%' }}
                            onChange={this.handleChangeRequirement}
                            value={this.state.requirement}
                            placeholder='Seleccione un requerimiento'
                          >
                            {requirements}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Item label='Usuario'>
                          <Select
                            mode='multiple'
                            style={{ width: '100%' }}
                            onChange={this.handleChangeUser}
                            value={this.state.user}
                            placeholder='Seleccione un usuario'
                          >
                            {users}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Tipo de reporte'>
                          <Select
                            style={{ width: '100%' }}
                            onChange={this.handleTypeReport}
                            value={this.state.type_report}
                            placeholder='Seleccione un tipo de reporte'
                          >
                            <Select.Option value='fulfilled'>Reporte de Cumplimiento</Select.Option>
                            <Select.Option value='FrequentRequests'>Solicitudes más frecuentes</Select.Option>
                            <Select.Option value='AreaRequests'>Solicitudes por área</Select.Option>
                            <Select.Option value='UserRequests'>Solicitudes realizadas por usuario</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item label='Generar reporte'>
                          <Button onClick={this.onSubmit}>Descargar reporte</Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default Report
