import React, { Component } from 'react'
import { Tag } from 'antd'
import API from '../../api/index'

class Action extends Component {
  constructor (props) {
    super(props)
    this.state = {
      user: props.user,
      is_active: props.user.isActive
    }
    this.handleAction = this.handleAction.bind(this)
  }

  componentWillReceiveProps (newProps) {
    if (newProps.user !== this.state.user) {
      this.setState({
        user: newProps.user,
        is_active: newProps.user.is_active
      })
    }
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  async handleAction () {
    await API.priority.update(this.state.user._id, {isActive: !this.state.is_active})
    this.updateState('is_active', !this.state.is_active)
  }

  render () {
    let color = 'green'
    let text = 'Activar'
    if (this.state.is_active) {
      color = 'red'
      text = 'Desactivar'
    }
    return (
      <Tag color={color} onClick={this.handleAction}>{text}</Tag>
    )
  }
}

export default Action
