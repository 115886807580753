import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import API from '../../api/index'
import {
  Table,
  Row,
  Col,
  Button,
  Tag
} from 'antd'

class ChangeModificationList extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      changeModification: [],
      result: [],
      redirect: undefined,
      page: 1
    }
    this.handleCreateModification = this.handleCreateModification.bind(this)
    this.handleViewChangeModification = this.handleViewChangeModification.bind(this)
    this.handleUpdateChangeModification = this.handleUpdateChangeModification.bind(this)
    this.handlePrev = this.handlePrev.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = this.initialState
    this.columns = [
      {
        title: 'Solicitante',
        dataIndex: 'applicant',
        key: 'applicant'
      },
      {
        title: 'Fecha de creación',
        dataIndex: 'created',
        key: 'created',
        render: (text, record) => {
          return (
            <span>{this.formatDate(record.created)}</span>
          )
        }
      },
      {
        title: 'Fecha de actualización',
        dataIndex: 'updated',
        key: 'updated',
        render: (text, record) => {
          let date = '------------------------------'
          if (record.updated) {
            date = this.formatDate(record.updated)
          }
          return (
            <span>{date}</span>
          )
        }
      },
      {
        title: 'Acción',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => {
          return (
            <span>
              <Tag color='blue' onClick={() => this.handleViewChangeModification(record._id)}>Ver detalle</Tag>
              {!record.isClose && (
                <Tag color='orange' onClick={() => this.handleUpdateChangeModification(record._id)}>Editar</Tag>
              )}
            </span>
          )
        }
      }
    ]
  }

  getMonth (index) {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return months[index]
  }

  formatDate (date) {
    const newDate = new Date(date)
    return ('0' + (newDate.getDate())).slice(-2) + ' de ' + this.getMonth(newDate.getMonth()) + ' del ' + newDate.getFullYear() + ', ' + ('0' + newDate.getHours()).slice(-2) + ':' + ('0' + newDate.getMinutes()).slice(-2)
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  async getChangeModification (page) {
    const changeModification = await API.changeModification.filter(page)
    this.updateState('changeModification', changeModification)
  }

  handleCreateModification () {
    this.updateState('redirect', '/change-modification/create')
  }

  handleViewChangeModification (id) {
    this.updateState('redirect', `/change-modification/detail/${id}`)
  }

  handleUpdateChangeModification (id) {
    this.updateState('redirect', `/change-modification/update/${id}`)
  }

  handleChange (event) {
    this.updateState(event.target.name, event.target.value)
  }

  componentDidMount () {
    this.getChangeModification(this.state.page)
  }

  handlePrev () {
    if (this.state.page > 1) {
      const page = this.state.page - 1
      this.updateState('page', page)
      this.getChangeModification(page)
    }
  }

  handleNext () {
    if (this.state.changeModification.length >= 10) {
      const page = this.state.page + 1
      this.updateState('page', page)
      this.getChangeModification(page)
    }
  }

  render () {
    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />)
    }
    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Col span={8}>
            <h3><strong>Listado de solicitudes de modificación de documentos</strong></h3>
          </Col>
          <Col offset={8} span={8} style={{ textAlign: 'right' }}>
            <Button onClick={this.handleCreateModification}>Crear solicitud de modificación</Button>
          </Col>
        </Row>
        <Table
          columns={this.columns}
          dataSource={this.state.changeModification}
          size='small'
          pagination={{
            position: 'none'
          }}
          rowKey={record => record._id}
        />
        <div style={{
          textAlign: 'right',
          marginTop: '10px'
        }}>
          <ul className='ant-pagination '>
            <li className={this.state.page === 1 ? ('ant-pagination-disabled ant-pagination-prev') : ('ant-pagination-prev')}>
              <span onClick={this.handlePrev}>Anterior</span>
            </li>
            <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>{this.state.page}</li>
            <li className={this.state.changeModification.length >= 10 ? ('ant-pagination-next') : ('ant-pagination-disabled ant-pagination-next')}>
              <span onClick={this.handleNext}>Siguiente</span>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default ChangeModificationList
