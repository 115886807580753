import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import API from '../../api/index'
import { getItem } from '../../api/utils'
import {
  Table,
  Row,
  Col,
  Button,
  Tag,
  Icon
} from 'antd'
import Action from './Action'

class PriorityList extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      priorities: [],
      result: [],
      redirect: undefined,
      page: 1,
      type: getItem('type')
    }
    this.handleCreatePriority = this.handleCreatePriority.bind(this)
    this.handleViewPriority = this.handleViewPriority.bind(this)
    this.handleUpdatePriority = this.handleUpdatePriority.bind(this)
    this.handlePrevPriority = this.handlePrevPriority.bind(this)
    this.handleNextPriority = this.handleNextPriority.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = this.initialState
    this.columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Tiempo de solución',
        dataIndex: 'timeString',
        key: 'timeString'
      },
      {
        title: 'Activo',
        dataIndex: 'isActive',
        key: 'isActive',
        render: (text, record) => {
          return (
            <span>
              {record.isActive ? (
                <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
              ) : (
                <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
              )}
            </span>
          )
        }
      },
      {
        title: 'Acción',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => {
          return (
            <span>
              <Tag color='blue' onClick={() => this.handleViewPriority(record._id)}>Ver detalle</Tag>
              <Tag color='orange' onClick={() => this.handleUpdatePriority(record._id)}>Editar</Tag>
              <Action user={record} />
            </span>
          )
        }
      }
    ]
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  async getPriorities (page) {
    const priorities = await API.priority.filter(page)
    this.updateState('priorities', priorities)
  }

  handleCreatePriority () {
    this.updateState('redirect', '/priority/create')
  }

  handleViewPriority (id) {
    this.updateState('redirect', `/priority/detail/${id}`)
  }

  handleUpdatePriority (id) {
    this.updateState('redirect', `/priority/update/${id}`)
  }

  handleChange (event) {
    this.updateState(event.target.name, event.target.value)
  }

  componentDidMount () {
    this.getPriorities(this.state.page)
  }

  handlePrevPriority () {
    if (this.state.page > 1) {
      const page = this.state.page - 1
      this.updateState('page', page)
      this.getPriorities(page)
    }
  }

  handleNextPriority () {
    if (this.state.priorities.length >= 10) {
      const page = this.state.page + 1
      this.updateState('page', page)
      this.getPriorities(page)
    }
  }

  render () {
    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />)
    }
    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Col span={8}>
            <h3><strong>Listado de prioridades</strong></h3>
          </Col>
          <Col offset={8} span={8} style={{ textAlign: 'right' }}>
            <Button onClick={this.handleCreatePriority}>Crear prioridad</Button>
          </Col>
        </Row>
        <Table
          columns={this.columns}
          dataSource={this.state.priorities}
          size='small'
          pagination={{
            position: 'none'
          }}
          rowKey={record => record._id}
        />
        <div style={{
          textAlign: 'right',
          marginTop: '10px'
        }}>
          <ul className='ant-pagination '>
            <li className={this.state.page === 1 ? ('ant-pagination-disabled ant-pagination-prev') : ('ant-pagination-prev')}>
              <span onClick={this.handlePrevPriority}>Anterior</span>
            </li>
            <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>{this.state.page}</li>
            <li className={this.state.priorities.length >= 10 ? ('ant-pagination-next') : ('ant-pagination-disabled ant-pagination-next')}>
              <span onClick={this.handleNextPriority}>Siguiente</span>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default PriorityList
