import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import verify from './VerifyLogin'

const AuthService = {
  isAuthenticated: false,
  authenticate () {
    const token = window.localStorage.getItem('token')
    if (token) {
      verify.validateSession()
      this.isAuthenticated = true
    }
  }
}

const SecretRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    AuthService.authenticate()
    return (AuthService.isAuthenticated === true ? <Component {...props} /> : <Redirect to='/' />)
  }} />
)

export default SecretRoute
