import React, { Component } from 'react'
import { Alert, Form, Button, Input, Icon, Modal } from 'antd'
import { Redirect } from 'react-router-dom'
import API from '../../api/index'
import Logo from '../../styles/img/oftalmolaser.png'
import '../../styles/css/Login.css'

const FormItem = Form.Item

class ResetPassword extends Component {
  constructor (props) {
    super(props)
    this.initialState = {
      email: '',
      error: false,
      messageError: '',
      redirect: false
    }
    this.state = this.initialState
    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  updateState (key, value) {
    this.setState({
      [key]: value
    })
  }

  onChange (event) {
    this.updateState(event.target.name, event.target.value)
  }

  async onSubmit () {
    const response = await API.auth.resetPassword(this.state.email)
    if (response.ok) {
      Modal.success({
        title: 'Olvidaste tu contraseña?',
        content: `Si existe una cuenta de Oftalmolaser para ${this.state.email}, se enviará un correo electrónico con más instrucciones.`
      })
      this.updateState('redirect', true)
    }
  }

  render () {
    if (this.state.redirect) {
      return (<Redirect to='/home' />)
    }
    return (
      <div className='center-block-login container-login'>
        <div style={{ minWidth: '415px' }}>
          <div className='text-center'>
            <a href='/' className='login-title'>
              <img src={Logo} alt='Oftalmolasersa' />
            </a>
            <p className='gray'>¿Olvidaste tu contraseña?</p>
          </div>
          <div>
            <Form onSubmit={this.onSubmit}>
              {this.state.error && (
                <FormItem>
                  <Alert message={this.state.messageError} type='warning' showIcon />
                </FormItem>
              )}
              <FormItem>
                <Input prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Ingrese su email' onChange={this.onChange} value={this.state.email} type='email' name='email' id='email' />
              </FormItem>
              <FormItem>
                <Button onClick={this.onSubmit}>Restablecer contraseña</Button>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}

export default ResetPassword
